import * as React from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'
import { CheckinStatus } from 'components/checkin-status-label'

type CheckinProgressIndicatorProps = {
  className?: string
  max?: number
  status: CheckinStatus | 'empty'
  value: string | number
}

export const CheckinProgressIndicator: React.VFC<
  CheckinProgressIndicatorProps
> = (props) => {
  const { className, max = 100, status, value } = props

  return (
    <progress
      className={cn(
        'rounded w-full',
        className,
        styles['checkin-progress-bar'],
        styles[status]
      )}
      max={max}
      value={value}
    >
      {value}%
    </progress>
  )
}
