import * as React from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

export type SkillChartsLegendProps = {
  noOfUsers: number
  title?: string
}

export const SkillChartsLegend = (props: SkillChartsLegendProps) => {
  const { noOfUsers, title } = props

  const text =
    noOfUsers > 1 ? 'Average required skill level' : 'Required skill level'

  return (
    <div className="flex flex-col items-center">
      {title && (
        <h6 className="font-bold mb-5 text-gray-900 text-sm">{title}</h6>
      )}
      <div className="flex gap-5 mb-3">
        <div className="flex gap-1.5 items-center">
          <div
            className={cn('h-3 rounded-full w-3', styles.working_towards)}
          ></div>
          <p className="mb-0 text-gray-600 text-xs">Working towards</p>
        </div>
        <div className="flex gap-1.5 items-center">
          <div className={cn('h-3 rounded-full w-3', styles.meeting)}></div>
          <p className="mb-0 text-gray-600 text-xs">Meeting</p>
        </div>
        <div className="flex gap-1.5 items-center">
          <div className={cn('h-3 rounded-full w-3', styles.exceeding)}></div>
          <p className="mb-0 text-gray-600 text-xs">Exceeding</p>
        </div>
      </div>
      <div>
        <div className="flex gap-1.5 items-center">
          <div className="bg-gray-200 h-3 rounded-full w-3"></div>
          <p className="mb-0 text-gray-600 text-xs">{text}</p>
        </div>
      </div>
    </div>
  )
}
