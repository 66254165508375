import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { PieChart, Pie, Cell, Tooltip, TooltipProps } from 'recharts'
import * as React from 'react'
import cn from 'classnames'
import pluralize from 'pluralize'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import styles from './styles.module.scss'

export type SkillRatioDonutChartProps = {
  counts: {
    exceeding: number
    meeting: number
    not_checked_in?: number
    working_towards: number
  }
  emptyState?: React.ReactNode
  hideTitle?: boolean
  loading?: boolean
}

export const SkillRatioDonutChart: React.VFC<SkillRatioDonutChartProps> = (
  props
) => {
  const { counts, emptyState, hideTitle, loading } = props

  const total = Object.values(counts).reduce((sum, value) => {
    return sum + value
  }, 0)

  const data =
    total > 0
      ? [
          { name: 'exceeding', total, value: counts.exceeding },
          { name: 'meeting', total, value: counts.meeting },
          { name: 'not_checked_in', total, value: counts.not_checked_in },
          { name: 'working_towards', total, value: counts.working_towards },
        ]
      : [{ name: 'no_checkins', total: 1, value: 1 }]

  return (
    <div
      className={cn('flex flex-col items-center', { 'animate-pulse': loading })}
    >
      {!hideTitle && (
        <>
          <h3 className="font-bold text-gray-900 text-xl">Skill ratio</h3>
          <p className="mb-5 text-gray-600 text-xs">
            Ratio of levels for all skills
          </p>
        </>
      )}
      <div className="relative">
        <PieChart
          className="flex !cursor-pointer"
          height={300}
          margin={{ bottom: 0, top: 0, left: 0, right: 0 }}
          width={300}
        >
          <Pie
            cx="50%"
            cy="50%"
            data={data}
            dataKey="value"
            innerRadius={130}
            outerRadius={150}
            stroke={total > 0 ? '#FFFFFF' : ''}
          >
            {data.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                className={cn('fill-current', styles[entry.name])}
              />
            ))}
          </Pie>
          {total > 0 && (
            <Tooltip
              content={<TooltipContent />}
              cursor={{ fill: 'transparent' }}
            />
          )}
        </PieChart>
        {total === 0 && !loading && emptyState}
      </div>
    </div>
  )
}

const TooltipContent = (props: TooltipProps<ValueType, NameType>) => {
  const { payload } = props

  const chartData = payload?.[0]

  if (!chartData) return null

  const numberOfSkills = chartData.payload.value

  const percentage = Math.round(
    (numberOfSkills / chartData.payload.total) * 100
  )

  return (
    <div className="bg-white border border-gray-100 flex gap-2 items-center min-w-[220px] p-3 rounded text-xs w-auto whitespace-nowrap">
      <CheckinStatusLabel status={chartData.payload.name} />
      <p className="mb-0 text-gray-600">
        {numberOfSkills} {pluralize('skill', numberOfSkills)} ({percentage}%)
      </p>
    </div>
  )
}
